<template>
  <div class="body">
    <div class="content-class">
      <div class="head-class">
        <div style="display: flex; align-items: center">
          <i class="el-icon-arrow-left" @click="back"></i>
          <div style="margin-left: 10px">新建工单</div>
        </div>
        <el-button type="primary" @click="submitClick('form')">保存</el-button>
      </div>
      <div class="item-title">
        <div class="title-left"></div>
        <div>工单信息</div>
      </div>
      <el-form
        label-width="120px"
        :model="itemForm"
        :rules="rules"
        class="form-class"
        ref="form"
      >
        <el-form-item label="工单类型：" required>
          <div class="work-class">
            <div
              @click="itemForm.workOrderType = item.value"
              :class="
                itemForm.workOrderType == item.value
                  ? 'select-class'
                  : 'unSelect-class'
              "
              v-for="(item, index) in workClassList"
              :key="index"
            >
              <div>{{ item.name }}</div>
              <img
                v-if="itemForm.workOrderType === item.value"
                class="img1"
                src="../../../assets/images/yunwei/select-backgroun.svg"
                alt=""
              />
              <div class="img2">
                <img
                  v-if="itemForm.workOrderType === item.value"
                  src="../../../assets/images/yunwei/selected.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </el-form-item>
        <div class="line-class">
          <el-form-item label="计划开始时间：" prop="planStartTime">
            <el-date-picker v-model="itemForm.planStartTime" type="date">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="计划结束时间：" prop="planEndTime">
            <el-date-picker v-model="itemForm.planEndTime" type="date">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="工单级别：" prop="orderLevel">
            <el-select
              v-model="itemForm.orderLevel"
              placeholder="请选择工单级别"
            >
              <el-option label="级别1" :value="0"></el-option>
              <el-option label="级别2" :value="1"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item label="工单内容：" prop="workOrderContent">
          <el-input
            type="textarea"
            :rows="1"
            v-model="itemForm.workOrderContent"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="修复建议：" prop="proposal">
          <el-input type="textarea" :rows="1" v-model="itemForm.proposal">
          </el-input>
        </el-form-item>
        <div class="line-class">
          <el-form-item label="电站名称" prop="powerStationCode">
            <el-input
              disabled
              v-model="powerStationName"
            ></el-input>
            <!-- <el-select
              v-model="itemForm.powerStationCode"
              placeholder="请选择电站"
            >
              <el-option
                v-for="item in powerStationList"
                :key="item.id"
                :label="item.powerStationName"
                :value="item.powerStationCode"
              >
              </el-option>
            </el-select> -->
          </el-form-item>
             <el-form-item label="工单流程名称" prop="workOrderNo">
            <el-select
              v-model="itemForm.workOrderName"
              placeholder="请选择工单流"
              @change="changeWork"
            >
              <el-option
                v-for="item in flowWorkOrderApplyList"
                :key="item.id"
                :label="item.workOrderName"
                :value="{
                  workOrderNo: item.workOrderNo,
                  workOrderName: item.workOrderName,
                }"
              >
              </el-option>
            </el-select>
            <!-- :value="{
                  workOrderNo: item.workOrderNo,
                  workOrderName: item.workOrderName,
                }" -->
          </el-form-item>
          <!-- <el-form-item label="工单流程名称" prop="workOrderNo">
            <el-select
              v-model="itemForm.workOrderNo"
              placeholder="请选择工单流"
            >
              <el-option
                v-for="item in flowWorkOrderApplyList"
                :key="item.id"
                :label="item.workOrderName"
                :value="item.workOrderNo"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
        </div>
      </el-form>
      <div class="item-title">
        <div class="title-left"></div>
        <div>任务信息</div>
      </div>
      <div
        style="
          display: flex;
          justify-content: flex-end;
          width: 100%;
          margin: 10px 0;
        "
      >
        <el-button type="primary" @click="addClick">新增</el-button>
      </div>
      <div class="table-class">
        <el-table :data="selectTableList" border style="width: 100%">
          <el-table-column
            type="index"
            label="序号"
            width="300px"
            align="center"
          ></el-table-column>
          <el-table-column
            label="厂站名称"
            show-overflow-tooltip
            align="center"
            width="140px"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.powerStationName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="告警名称" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.signalName }}</span>
            </template>
          </el-table-column>

          <el-table-column label="告警类型" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.signalTypeName }}</span>
            </template>
          </el-table-column>
        </el-table>
        <!-- <el-table :data="selectTableList" style="width: 100%">
          <el-table-column label="序号" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.index }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="厂站名称"
            prop="number"
            sortable="custom"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.powerStationName }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="任务详情"
            prop="number"
            sortable="custom"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.jobInfo }}</span>
            </template>
          </el-table-column>

          <el-table-column label="发生时间" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.occurTime }}</span>
            </template>
          </el-table-column>
        </el-table> -->
      </div>
    </div>
    <el-dialog
      title="新增"
      :visible.sync="addDialogVisible"
      class="dialog-bg"
      @close="cancel('dialogForm')"
      width="80%"
    >
      <el-table
        :data="tableData2"
        border
        style="width: 100%"
        height="580px"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center">
        </el-table-column>
        <el-table-column label="厂站名称" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.powerStationName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="设备名称" align="center">
          <template slot-scope="scope">
            <span style="color: #80ffff">{{ scope.row.deviceName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="告警名称" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.signalName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="告警类型" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.signalTypeName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="级别" align="center">
          <template slot-scope="scope">
            <span style="color: #f59a23">{{
              scope.row.level == 1 ? "紧急" : "正常状态"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" align="center" width="80px">
          <template slot-scope="scope">
            <span style="color: #75bb65" v-if="scope.row.status == 1"
              >激活</span
            >
            <span style="color: red" v-if="scope.row.status == 0">消缺</span>
          </template>
        </el-table-column>
        <el-table-column label="修复建议" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.dealMessage }}</span>
          </template>
        </el-table-column>

        <el-table-column label="产生时间" width="95px" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.createtime }}</span>
          </template>
        </el-table-column>
        <el-table-column label="恢复时间" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.status == 1 ? "" : scope.row.updatetime }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="操作" align="center" width="100px">
          <template slot-scope="scope">

            <el-button type="text" @click="createWorkOrderApply(scope.row)"
              >转工单</el-button
            >

          </template>
        </el-table-column> -->
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.currentPage"
        :page-sizes="[2, 5, 10, 15]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        class="pagination-style"
        :total="total"
      ></el-pagination>

      <el-row type="flex" justify="center">
        <el-button type="primary" @click="cancel('dialogForm')">取消</el-button>
        <el-button type="primary" @click="submitForm('dialogForm')"
          >保存</el-button
        >
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import { formatDate } from "../../../utils/formatDate";

export default {
  data() {
    return {
      itemForm: {
        workOrderType: 0,
      },
      workClassList: [
        { name: "消缺", value: 0 },
        { name: "清洗", value: 1 },
        { name: "检修", value: 2 },
        { name: "抢修", value: 3 },
        { name: "其他", value: 4 },
      ],
      rules: {
        planStartTime: [{ required: true, message: "计划开始时间不能为空" }],
        planEndTime: [{ required: true, message: "计划结束时间不能为空" }],
        orderLevel: [{ required: true, message: "工单级别不能为空" }],
        workOrderContent: [{ required: true, message: "工单内容不能为空" }],
        proposal: [{ required: true, message: "修复建议不能为空" }],
        // powerStationCode: [{ required: true, message: "电站名称不能为空" }],
        workOrderNo: [{ required: true, message: "工单流程名称不能为空" }],
      },
      currentPage: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      tableItemRules: {
        alarmRecordId: [{ required: true, message: "告警名称不能为空" }],
        occurTime: [{ required: true, message: "发生时间不能为空" }],
      },
      powerStationList: [], //电站列表
      alartList: [], //告警列表
      flowWorkOrderApplyList: [], //工单流程列表
      addDialogVisible: false,
      dialogForm: {}, // 弹框表单
      workOrderDetailInfo: [],
      powerStationName: localStorage.getItem("powerStationName"),
      tableData2: [], //新增按钮表格
      queryInfo: {
        currentPage: 1,
        pageSize: 10,
      },
      selectTableList: [], //选中的数据
    };
  },
  mounted() {

    console.log("this.$route.query.id", this.$route.query.id);
    if (this.$route.query.id) {
      this.getDetail();
    }
  },
  created() {
    this.getTableList();
        this.getUserInfo();
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    // 新增任务信息
    addClick() {
      this.addDialogVisible = true;
    },
    // 提交
    submitClick() {
      this.$refs.form.validate(async (isOk) => {
        if (isOk) {
          let data = {
            createCode: JSON.parse(localStorage.getItem("userInfo")).userCode,
            createName: JSON.parse(localStorage.getItem("userInfo")).name,
            jsonData: {
              tableData: this.selectTableList,
            },
            workOrderName: this.itemForm.workOrderName,
            workOrderNo: this.itemForm.workOrderNo,
            workOrderOverTime: "",
            workOrderType: this.itemForm.workOrderType,
            orderLevel: this.itemForm.orderLevel,
            workOrderContent: this.itemForm.workOrderContent,
            planStartTime: this.itemForm.planStartTime,
            planEndTime: this.itemForm.planEndTime,
            proposal: this.itemForm.proposal,
            powerStationCode: localStorage.getItem("powerStationCode"),
            // powerStationName: localStorage.getItem("powerStationName"),
          };
          const res = await this.$http.post(
            "WorkOrder/createWorkOrderApply",
            data
          );
          if (res.data.code === 200) {
            this.$message.success("提交成功");
            this.$router.push("/yunwei/yunwei/workOrderManage");
          } else {
            this.$message.error(res.data);
          }
        }
      });
    },
        changeWork(val) {
      this.itemForm.workOrderName = val.workOrderName;
      this.itemForm.workOrderNo = val.workOrderNo;
    },
    // 保存弹框
    submitForm(formName) {
      this.addDialogVisible = false;

    },
    cancel(formName) {
      this.addDialogVisible = false;
      setTimeout(() => {
        this.dialogForm = {};
        // this.$refs[formName].resetFields();
      }, 500);
    },
    getUserInfo() {
      let condition = {
        powerStationCode: localStorage.getItem("powerStationCode"),
      };
      // // 告警列表
      // this.$http
      //   .post("alarmManage/list", { currPage: 1, pageSize: 10000 })
      //   .then((res) => {
      //     if (res.data.code === 200) {
      //       this.alartList = res.data.data.data;
      //     }
      //   });

      // // 电站列表
      // this.$http
      //   .post("powerStation/list", { currPage: 1, pageSize: 10000, condition })
      //   .then((res) => {
      //     if (res.data.code == 200) {
      //       this.powerStationList = res.data.data.data;
      //     } else {
      //       this.$message.error(res.data.message);
      //     }
      //   });
      // 工单流程列表
      this.$http
        .post("WorkOrder/workList", { currPage: 1, pageSize: 10000, condition })
        .then((res) => {
          if (res.data.code == 200) {
            this.flowWorkOrderApplyList = res.data.data.data;
          } else {
            this.$message.error(res.data.message);
          }
        });
    },

    getDetail() {
      this.$http
        .post("WorkOrder/workOrderInfo", {
          workOrderApplyCode: this.$route.query.id,
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.workOrderDetailInfo = res.data.data;
            this.workOrderDetailInfo.jsonData = JSON.parse(
              this.workOrderDetailInfo.jsonData
            );
            this.itemForm = {
              workOrderType: this.workOrderDetailInfo.workOrderType,
              orderLevel: this.workOrderDetailInfo.orderLevel,
              powerStationCode:
                this.workOrderDetailInfo.powerStationOwnerUserCode,
              workOrderName: this.workOrderDetailInfo.workOrderName,
              workOrderNo: this.workOrderDetailInfo.workOrderNo,
              planStartTime: this.workOrderDetailInfo.jsonData.planStartTime,
              planEndTime: this.workOrderDetailInfo.jsonData.planEndTime,
              orderLevel: this.workOrderDetailInfo.jsonData.orderLevel,
              workOrderContent:
                this.workOrderDetailInfo.jsonData.workOrderContent,
              proposal: this.workOrderDetailInfo.jsonData.proposal,
            };
            this.tableData = this.workOrderDetailInfo.jsonData.jobInfoList;
          }
        });
    },
    // 获取表格数据
    getTableList() {
      let params = {
        condition: {
          createCode: JSON.parse(localStorage.getItem("userInfo")).userCode,
          status: 1,
          powerStationCode: localStorage.getItem("powerStationCode"),
          level: 1,
        },
        currPage: this.queryInfo.currentPage,
        pageSize: this.queryInfo.pageSize,
      };
      this.$http.post("/deviceAlarmRecord/list", params).then((res) => {
        if (res.data.code === 200) {
          this.tableData2 = res.data.data.data;
          this.total = res.data.data.count;
        }
      });
    },
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getTableList();
    },
    handleCurrentChange(val) {
      this.queryInfo.currentPage = val;
      this.getTableList();
    },
    // 全选
    handleSelectionChange(val) {
      this.selectTableList = val;
    },
  },
};
</script>
<style lang="scss" scoped>
.body {
  background: #21527e;
  padding: 10px;
  height: 100%;
  overflow-y: auto;
  .content-class {

    padding: 8px;
    width: 80%;
    margin-left: 100px;
    .head-class {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: "Arial", sans-serif;
      color: #97b3cb;
      font-size: 16px;
    }
    .item-title {
      display: flex;
      align-items: center;
      font-family: "Arial", sans-serif;
      color: #ffffff;
      font-size: 15px;
      margin: 15px 5px;
      .title-left {
        height: 20px;
        width: 5px;
        border-radius: 5px;
        border: 1px solid #02a7f0;
        background-color: #02a7f0;
        margin-right: 5px;
      }
    }
    .form-class {
      .work-class {
        display: flex;
        .select-class {
          width: 105px;
          height: 33px;
          border-radius: 5px;
          border: 1px solid #02a7f0;
          background-color: #21527e;
          font-family: "Arial", sans-serif;
          color: #02a7f0;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          margin-right: 5px;
          .img1 {
            width: 21px;
            height: 15px;
            position: absolute;
            bottom: -1px;
            right: -1px;
            z-index: 1;
          }
          .img2 {
            width: 19.5px;
            height: 14px;
            position: absolute;
            bottom: 0px;
            right: 0px;
            background: #02a7f0;
            img {
              width: 16px;
              height: 10px;
              position: absolute;
              bottom: 0px;
              right: 0px;
            }
          }
        }
        .unSelect-class {
          width: 105px;
          height: 33px;
          border-radius: 5px;
          border: 1px solid rgba(242, 242, 242, 0.57);
          background-color: rgba(255, 255, 255, 0);
          font-family: "Arial", sans-serif;
          color: #f2f2f2;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 5px;
        }
      }
      .line-class {
        display: flex;
        // justify-content: space-between;
      }
      /deep/ .el-form-item__label {
        box-sizing: border-box;
        font-family: "Arial", sans-serif;
        color: #fff;
        font-size: 13px;
        width: 150px;
      }
      /deep/ .el-input__inner {
        box-sizing: border-box;
        font-family: "Arial", sans-serif;
        color: #fff;
        background: #21527e;
        font-size: 13px;
        border: 1px solid rgba(242, 242, 242, 0.57);
      }
      /deep/ .el-textarea__inner {
        box-sizing: border-box;
        font-family: "Arial", sans-serif;
        color: #fff;
        background: #21527e;
        font-size: 13px;
        border: 1px solid rgba(242, 242, 242, 0.57);
      }
      /deep/ .el-upload--picture-card {
        background: #21527e;
        height: 120px;
        width: 120px;
      }
      /deep/ .el-upload-list--picture-card .el-upload-list__item {
        background: #21527e;
        height: 120px;
        width: 120px;
      }

      .upload-col {
        img {
          width: 60px;
          height: 60px;
        }
      }
      .upload-col {
        img {
          height: 40px;
          width: 40px;
        }
      }
    }
  }
  .dialog-bg{
    // /deep/.el-dialog{
    //   background: #fcfcfc;
    //   // .el-dialog__header{
    //   //   .el-dialog__title{
    //   //     color: #ffffff;
    //   //   }
    //   // }
    // }
    /deep/.el-pager li{
      background-color: #fff;
      color: #303133;
    }
    /deep/.el-pager li.active {
      color: #409EFF;
    }
    /deep/.btn-prev,/deep/.btn-next{
      color: #303133;
    }
    /deep/.el-pagination__total,/deep/.el-pagination__jump{
      color: #606266;
    }
  }
}


.table-class {
  /deep/ .el-table--border,
.el-table--group {
  border: 1px solid rgba(151, 179, 203, 0.55);
}
/deep/ .el-table--border th {
  background-color: #21527e;

  border: 1px solid #737c85;
  box-sizing: border-box;
  font-family: "微软雅黑", sans-serif;
  color: #ffffff;
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #223f6c !important;
}

/deep/ .el-table th {
  background-color: #2d5981;
  border: 1px solid rgba(151, 179, 203, 0.55);
  box-sizing: border-box;
  font-family: "微软雅黑", sans-serif;
  color: #ffffff;
}
/deep/ .el-table td {
  background-color: #2d5981;
  border: 1px solid rgba(151, 179, 203, 0.55);
  box-sizing: border-box;
  font-family: "微软雅黑", sans-serif;
  color: #ffffff;
}
/deep/ .el-table__body-wrapper {
  background: #2d5981;
}
}
/deep/ .el-table {
  margin-top: 0px;
}

/deep/ .el-pagination button:disabled {
  background: rgba(255, 255, 255, 0);
}
/deep/ .el-pager li.active {
  color: #f2f2f2;
  background-color: #00b2e6;
  cursor: default;
}
/deep/ .el-pagination {
  display: flex;
  justify-content: flex-end;
}
/deep/ .el-pagination__total {
  color: #fff;
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  font-size: 12px;
}
/deep/ .el-pagination__jump {
  color: #fff;
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  font-size: 15px;
}

.dialog-class {
  /deep/ .el-form-item__label {
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    color: #333333;
    font-size: 15px;
    width: 150px;
  }
  /deep/ .el-input__inner {
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    color: #333333;
    font-size: 13px;
    background: #223f6c;
  }
}

::v-deep .el-checkbox {
  margin-right: 0;
}
::v-deep .el-dialog__body {
  padding: 20px 30px !important;
}
// ::v-deep .el-input.is-disabled .el-input__inne {
//   background-color: #223f6c !important ;
// }
</style>
